import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';

const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_S3_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY
    }
});

const makeid = (length) =>{
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}


export const uploadFileToS3 = async (file, onProgress, onSuccess, onFail) => {
    try {
        let fileId = makeid(10);
        const upload = new Upload({
            client: s3Client,
            params: {
                Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
                Key: "images/"+fileId+"."+file.name.split('.').pop(),
                Body: file,
                ContentType: file.type
            }
        });

        upload.on('httpUploadProgress', (progressEvent) => {
            onProgress(progressEvent);
        });

        const result = await upload.done();
        onSuccess(result);
    } catch (error) {
        console.error('Upload failed:', error);
        onFail(error);
    }
};